<template>
<FooterView />
</template>

<script>
import FooterView from "./_components/FooterView.vue"
export default {
    name: "HeaderModule",
    components: {
        FooterView
    }
}
</script>
