<template>
<LoaderComponent v-if="this.loader" />
<div class="container userDashboard">
    <div class="row">
        <div class="col-nd-7 col-sd-12 col-md-12 dashboardDiv1">
            <div class="headContainerdiv mb-0">
                <div class="row">
                    <div class="col-md-12">
                        <div class="ps-block__left imgmaindiv headText">
                            User Dashboard
                        </div>
                    </div>
                </div>

            </div>
            <div class="sls custdashboardDiv">
                <div class="row">
                    <div class="col-md-12 w-50 h-50 gap-2">
                        <div class="row">
                            <div class="col-md-3">
                                <Card>
                                    <template #title>Requested Quotes</template>
                                    <template #content>
                                        <p class="m-0">
                                           {{ this.quoteslist.length }}
                                            Request Quote
                                        </p>
                                    </template>
                                </Card>
                            </div>
                            <div class="col-md-3">
                                <Card>
                                    <template #title>Inprogress Quotes</template>
                                    <template #content>
                                        <p class="m-0">
                                           {{this.respquotelist.length}}
                                           InProgress Quote
                                        </p>
                                    </template>
                                </Card>
                            </div>
                            <div class="col-md-3">
                                <Card>
                                    <template #title>Approved Orders</template>
                                    <template #content>
                                        <p class="m-0">
                                            {{ this.approveditems.length }}
                                            Approved
                                        </p>
                                    </template>
                                </Card>
                            </div>
                            <div class="col-md-3">
                                <Card>
                                    <template #title>Delivered Orders</template>
                                    <template #content>
                                        <p class="m-0">
                                            {{ this.delivereditems.length }}
                                            Delivered
                                        </p>
                                    </template>
                                </Card>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-md-6">
                                <Card>
                                    <template #title>Resonse Quote</template>
                                    <template #content>
                                        <apexchart type="bar" height="350" :options="columnChartState.chartOptions" :series="columnChartState.series"></apexchart>
                                    </template>
                                </Card>
                            </div>
                            <div class="col-md-6">
                                <Card>
                                    <template #title>Delivered Order </template>
                                    <template #content>
                                        <apexchart type="area" ref="chart" height="350" 
                                        :options="lineChartState.chartOptions"
                                        :series="lineChartState.series"></apexchart>
                                    </template>
                                </Card>
                            </div>
                        </div>
                        <!-- <div class="row mt-2">
                            <div class="col-md-6">
                                <Card>
                                    <template #title>Approved Orders</template>
                                    <template #content>
                                        <apexchart type="area" height="350" :options="areaChartState.chartOptions" :series="areaChartState.series"></apexchart>
                                    </template>
                                </Card>
                            </div>
                            <div class="col-md-6 pieChartDiv">
                                <Card>
                                    <template #title>Delivered Order</template>
                                    <template #content>
                                        <apexchart type="pie" width="380"  :options="pieChartState.chartOptions" :series="pieChartState.series"></apexchart>
                                    </template>
                                </Card>
                            </div>
                        </div> -->
                    </div>
                    <!-- <div class="col-md-4 w-90 h-75">
                        <div class="row">
                            <div class="col-md-12">
                                <Card>
                                    <template #title>Customer Satisfaction</template>
                                    <template #content>
                                        <apexchart type="pie" width="380"  :options="pieChartState.chartOptions" :series="pieChartState.series"></apexchart>
                                    </template>
                                </Card>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-md-12">
                                <Card>
                                    <template #title>Inventories</template>
                                    <template #content>
                                        <p class="m-0">
                                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore sed consequuntur error repudiandae numquam deserunt quisquam repellat libero asperiores earum nam nobis, culpa ratione quam perferendis esse, cupiditate neque
                                            quas!
                                        </p>
                                    </template>
                                </Card>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import LoaderComponent from "@/components/LoaderComponent.vue";
import dateFormat from '@vue-formily/date-format';
export default {
    name: 'customerDashboard',
    components: {
        LoaderComponent
    },
    data() {
        return {
            loader: false,
            lineChartState: {
                series: [{
                    name: "Delivered Order",
                    data: []
                }],
                chartOptions: {
                    chart: {
                        height: 350,
                        type: 'line',
                        zoom: {
                            enabled: false
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'straight'
                    },
                    title: {
                        text: 'Product Trends by Month',
                        align: 'left'
                    },
                    grid: {
                        row: {
                            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                            opacity: 0.5
                        },
                    },
                    markers: {
                        size: 1
                    },
                    // xaxis: {
                    //     categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
                    // }
                    xaxis: {
                        type: 'datetime',
                        labels: {},
                        categories: [],
                        title: {
                            text: 'Dates'
                        }
                    },
                    yaxis: {
                        title: {
                            text: 'Status Count'
                        },
                        categories: [1,2,3],
                    },
                    tooltip: {
                        shared: true,
                        x: {
                            format: 'dd/MM/yy HH:mm'
                        },
                    },
                    legend: {
                        position: 'bottom',
                        horizontalAlign: 'center',
                    },

                },
            },
            columnChartState: {
                series: [{
                    name: 'PRODUCT A',
                    data: [44, 55, 41, 67, 22, 43]
                }, {
                    name: 'PRODUCT B',
                    data: [13, 23, 20, 8, 13, 27]
                }, {
                    name: 'PRODUCT C',
                    data: [11, 17, 15, 15, 21, 14]
                }, {
                    name: 'PRODUCT D',
                    data: [21, 7, 25, 13, 22, 8]
                }],
                chartOptions: {
                    chart: {
                        type: 'bar',
                        height: 350,
                        stacked: true,
                        toolbar: {
                            show: true
                        },
                        zoom: {
                            enabled: true
                        }
                    },
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            legend: {
                                position: 'bottom',
                                offsetX: -10,
                                offsetY: 0
                            }
                        }
                    }],
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            borderRadius: 10,
                            dataLabels: {
                                total: {
                                    enabled: true,
                                    style: {
                                        fontSize: '13px',
                                        fontWeight: 900
                                    }
                                }
                            }
                        },
                    },
                    xaxis: {
                        type: 'datetime',
                        categories: ['01/01/2011 GMT', '01/02/2011 GMT', '01/03/2011 GMT', '01/04/2011 GMT',
                            '01/05/2011 GMT', '01/06/2011 GMT'
                        ],
                    },
                    legend: {
                        position: 'right',
                        offsetY: 40
                    },
                    fill: {
                        opacity: 1
                    }
                },
            },
            areaChartState: {
                series: [{
                    name: 'series1',
                    data: [31, 40, 28, 51, 42, 109, 100]
                }, {
                    name: 'series2',
                    data: [11, 32, 45, 32, 34, 52, 41]
                }],
                chartOptions: {
                    chart: {
                        height: 350,
                        type: 'area'
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    xaxis: {
                        type: 'datetime',
                        categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
                    },
                    tooltip: {
                        x: {
                            format: 'dd/MM/yy HH:mm'
                        },
                    },
                },

            },
            pieChartState: {
                series: [44, 55, 13, 43, 22],
                chartOptions: {
                    chart: {
                        width: 380,
                        type: 'pie',
                        height:400

                    },
                    labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200
                            },
                            legend: {
                                position: 'bottom'
                            }
                        }
                    }]
                },
            },
            quoteslist: [],
            respquotelist:[],
            approveditems:[],
            delivereditems:[],
            categories: [],
            chartDateList: []
        };
    },
    methods: {
        async fetchQuoteList() {
            var loginid = localStorage.getItem('loginid');
            let formData = new FormData();
            formData.append('search_by_quote_id','');
            formData.append('categoryname', '');
            formData.append('product', '');
            formData.append('reqdate', '');
            formData.append('user_id', loginid);
            formData.append('usertype','Customer');
            var token = localStorage.getItem("token");
            const headers = {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'multipart/form-data'
            };
            this.loader = true;
            this.axios.post(
                    process.env.VUE_APP_API_ENDPOINT + '/get-reqquote', formData, {
                        headers
                    })
                .then(response => {
                    if(response.status == 200){
                        this.loader = false;
                        this.testflag = true;
                        this.quoteslist = response.data.data;
                    }
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status == 401 || error.response.status == 404  || error.response.status == 400) {
                            localStorage.clear();
                            if (this.$route.name != 'home') {
                                    this.$router.push({
                                    name: 'home'
                                });
                            } 
                        }
                    }
                    else{
                        if(error.message == "Network Error"){
                            localStorage.clear();
                            if (this.$route.name != 'home') {
                                    this.$router.push({
                                    name: 'home'
                                });
                            } 
                        }
                    }
                });
        },
        async fetchQuoteRespList() {
            let formData = new FormData();
            var token = localStorage.getItem("token");
            var loginid = localStorage.getItem("loginid");
            formData.append('search_by_quote_id', '');
            formData.append('productname', '');
            formData.append('user_id', loginid);
            formData.append('flag','noapproveddata');
            formData.append('userrole', 3);
            const headers = {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'multipart/form-data'
            };
            this.loader = true;
            this.axios.post(
                    process.env.VUE_APP_API_ENDPOINT + '/searchreqresp', formData, {
                        headers
                    })
                .then(response => {
                    this.loader = false;
                    this.testflag = true;
                    this.respquotelist =(response.data.data).filter(item=>item.status==='InProgress');
                })
                .catch(err => {});
        },
        async fetchOrder() {
            console.log('fetching order....');
            let formData = new FormData();
            var token = localStorage.getItem("token");
            var user_id = localStorage.getItem('loginid');
            formData.append('categoryname', '');
            formData.append('order_id', '');
            formData.append('order_date', '');
            formData.append('user_id', user_id);
            formData.append('userrole', 3);
            const headers = {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'multipart/form-data'
            };
            this.loader = true;
            this.axios.post(
                    process.env.VUE_APP_API_ENDPOINT + '/searchorder', formData, {
                        headers
                    })
                .then((response) => {
                    this.loader = false;
                    this.testflag = true;
                    var data = response.data.data;
                    this.approveditems = (response.data.data).filter(item=>item.status==='Approved');
                    this.delivereditems = (response.data.data).filter(item=>item.status==='Delivered');

                    console.log('delivereditems-', this.delivereditems)
                    //For Delivered Order
                    var datelist = [];
                    var series = [];
                    var status = ['Delivered'];
                    Object.values(this.delivereditems).forEach((el) => {
                        console.log('el item-', el);
                        console.log('hhh2')
                        var d = new Date(el.updated_at);
                        var dd = dateFormat.format("yyyy.MM.dd", d);
                        if (!datelist.includes(dd)) {
                            datelist.push(dd);
                            this.chartDateList.push(dd);
                        }
                    });
                    console.log('chartDateList//--',this.chartDateList, datelist);
                    var datelist = datelist.sort();
                    let deliveryCounts = Array(12).fill(0);

                    for (let s of status) {
                        let temp_res = {
                            'name': s,
                            'data': [] //0,0,0,0,3,0,0,0,0,0,0,0
                        }
                        for (let date of datelist) {
                            var temp = this.delivereditems.filter((x) => {
                                var d = new Date(x.updated_at);
                                var compare_dt = dateFormat.format("yyyy.MM.dd", d);
                                return x.status == s && compare_dt == date
                            })
                            temp_res.data.push(temp.length)
                        }
                        series.push(temp_res);
                    }
                    console.log('datelist-',datelist, series);
                    var vm = this;
                    vm.lineChartState.chartOptions = {
                            ...vm.lineChartState.chartOptions,
                            ...{
                                markers: {
                                    size: 5,
                                    hover: {
                                        size: 9
                                    }
                                },
                                xaxis: {
                                    type: 'month',
                                    min: new Date(datelist[0]).getTime(),
                                    max: new Date(datelist[(datelist.length) - 1]).getTime(),
                                    // labels: {
                                    //     formatter: function (val, timestamp) {
                                    //         var date = new Date(timestamp);
                                    //         return dateFormat.format('yyyy.MM.dd', date);
                                    //     }
                                    // },
                                    // categories: datelist.map((e) => {
                                    //     return e;
                                    // })
                                    categories:['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep']

                                },
                                yaxis:{
                                    categories:[1,2,3]
                                }
                            },
                        },
                        vm.lineChartState.series = series;
                        console.log('  vm.lineChartState.series -',   vm.lineChartState.series );
                })
                .catch(err => {});
        },
    },
    mounted() {
        // this.loader = true;
        this.usertype = localStorage.getItem('userType');
        this.fetchQuoteList();
        this.fetchQuoteRespList();
        this.fetchOrder();
    }
};
</script>

<style scoped>
.custdashboardDiv .pieChartDiv .p-card.p-component{
    height: 410px !important
}
</style>
