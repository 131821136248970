<template>
<LoaderComponent v-if="this.loader" />
<div class="container-fluid" style="background-color: #ffffff; margin-top: -20px;
        margin-bottom: 60px;padding: 20px;">
    <div class="QuestionDiv" v-if="this.userType='Customer'">
        <div class="row">
            <div class="col-md-12 mt-0 quesdiv">
                <div class="form">
                    <div class="row">
                        <div class="col-12 col-md-4 col-sm-4 col-lg-4">
                            <ChipsMultiSelect v-model="searchItem" display="chip" :options="this.catoptions" filter optionLabel="title"
                             :maxSelectedLabels="this.catoptions.length" optionValue="value"
                             class="select select-sm multiselsearchBox w-full md:w-20rem" style="margin: 1px !important;" />
                            <div class="select__arrow dropdownIcom unitdropdownIcon"></div>
                        </div>
                        <div class="col-12  col-md-4 col-sm-4 col-lg-4">
                            <button class="ps-btn success submitbtn" @click.prevent="searchCategory">Search</button>
                        </div>
                        <div class="col-12  col-md-4 col-sm-4 col-lg-4"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" v-for="(category, index) in categorizedFavSuppliers.slice(0, visibleCategories)" :key="index">
            <div class="col-md-12 mt-8 quesdiv ml-1">
                <div class="form categoriDiv">
                    <h4>{{ category.category_name }}</h4>
                    <div class="row">
                        <div class="col-12 col-md-2 col-sm-2 col-lg-2 mb-0 cardDiv" :class="item.id>1?'otherCardDiv':''" v-for="item in category.suppliers" :key="item.id">
                            <Card style="overflow: hidden" @click="requestAQuote(item.supplier_id, item.category_id)" :class="item.userrole.approval_status=='Disapproved'?'disableFavSupplierCard':'favSupplierCard'">
                                <template #header>
                                    <div class="ppimgDiv">
                                        <img alt="user_profile_pic" class="img-fluid ppicture" v-if="item.user_profile.profile_picture ==null" :src="this.suppimg" style="margin-left: 110%;"  />
                                         <img alt="user_profile_pic" class="img-fluid ppicture" v-else :src="`${this.srcimage+'uploads/profiles_pictures/'
                                        +item.user_profile.profile_picture}`" style="margin-left: 110%;"  />
                                    </div>
                                </template>
                                <template #content>
                                    <div class="flex-wrap devideDiv align-content-center mt-5">
                                        <sapn>{{item.userrole.ref_id}}</sapn><br />
                                        <sapn style="color:crimson" v-if="item.userrole.approval_status=='Disapproved'">{{item.userrole.approval_status}}</sapn>
                                    </div>
                                </template>
                                <template #footer>
                                    <div class="flex gap-3 justify-content-between p-2"  :class="item.userrole.approval_status=='Disapproved'?'mt-1':'mt-5'">
                                        <div class="flex-wrap devideDiv">
                                            <font-awesome-icon :icon="['fas', 'star']" class="starIcon"/>
                                           {{ item.rating }}
                                        </div>
                                        <div class="flex-wrap devideDiv">
                                            <font-awesome-icon :icon="['fas', 'heart']" :id="item.id" @click="deleteFavSupp(item.supplier_id, item.category_id)"/>
                                            {{item.favorited_count}}
                                        </div>
                                    </div>
                                </template>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 mb-4 mt-5">
                <span class="icon-arrow-left backarrow ml-2 mt-2" @click="backtoparent">&nbsp;Back</span>
            </div>
            <div class="col-md-6 mb-4">
                <router-link to="#" style="top: 2.8rem;color: rgb(7 137 201); float: right;"  @click="viewMore"
                v-if="visibleCategories < categorizedFavSuppliers.length">View more...</router-link>
                <router-link to="#" style="top: 2.8rem;color: rgb(7 137 201);  float: right;" @click="viewLess"
                v-else-if="visibleCategories > 2">View less</router-link>
            </div>
        </div>
    </div>
</div>
</template>

    
<script>
import LoaderComponent from "@/components/LoaderComponent.vue";
import {
    watch
} from "vue";
export default {
    name: 'FavSuppComponent',
    components: {
        LoaderComponent
    },
    data: () => ({
        suppimg:require('@/assets/img/admin/default_user_img.png'),
        searchItem:[30,31,32],
        catoptions: [],
        favSupplierList:[],
        siteval: null,
        loader: false,
        unit: [],
        srcimage: process.env.VUE_APP_SET_PATH,
        categorizedFavSuppliers:[],
        visibleCategories: 2,
    }),
    methods: {
        backtoparent() {
            this.$router.back();
        },
        deleteFavSupp(supplier_Id, categoryId){
            console.log('id-',categoryId, supplier_Id);
            this.$swal({
                title: 'Do you want to remove this supplier from your favourite list?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                showCloseButton: true,
                showLoaderOnConfirm: true,
                customClass: 'warningswal'
            }).then((result) => {
                if(result.isConfirmed){
                    let formData = new FormData();
                    let user_id = localStorage.getItem('loginid');
                    formData.append('user_id', user_id);
                    formData.append('supplierid', supplier_Id);
                    var token = localStorage.getItem("token");
                    const headers = {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'multipart/form-data'
                    };
                    this.axios.post(
                        process.env.VUE_APP_API_ENDPOINT + `/delete_favsupp/${categoryId}`,formData, {
                            headers
                        })
                        .then(response => {
                            console.log('response-', response.data.data);
                            if (response.status == 200) {
                                this.updateCategorizedFavSuppliers();
                            }
                        })
                        .catch(error => {
                            if (error.response) {
                                if (error.response.status == 401 || error.response.status == 404 || error.response.status == 400) {
                                    localStorage.clear();
                                    if (this.$route.name != 'home') {
                                        this.$router.push({
                                            name: 'home'
                                        });
                                    }
                                }
                            } else {
                                if (error.message == "Network Error") {
                                    localStorage.clear();
                                    if (this.$route.name != 'home') {
                                        this.$router.push({
                                            name: 'home'
                                        });
                                    }
                                }
                            }
                        });
                } 
            })
            .catch(()=>{  console.error('An error occurred:', error);});
        },
        viewMore() {
            this.visibleCategories = this.categorizedFavSuppliers.length;
        },
        viewLess() {
            this.visibleCategories = 2;
        },
        searchCategory(){
            this.updateCategorizedFavSuppliers();
        },
        async allcategory() {
            var array = [];
            const headers = {
                'Authorization': 'Bearer ' + localStorage.getItem("token"),
            };
            await this.axios.get(process.env.VUE_APP_API_ENDPOINT + '/categorylist', {})
                .then(response => {
                    Object.values(response.data.data).forEach(value => {
                        if (this.$route.query.catid) {
                            if (this.$route.query.catid == value.id) {
                                this.form.categoryget = value.id;
                            }
                        }
                        array.push({
                            'value': value.id,
                            "title": value.name
                        });
                    });
                    this.catoptions = array;
                })
                .catch(err => {});
        },
        updateCategorizedFavSuppliers() {
            console.log('search-', this.searchItem);
            var categoryIds=[];
            for(const [key , value] of Object.entries(this.searchItem)){

                categoryIds.push(value)
            }
            console.log('categoryIds-', categoryIds);
            var token = localStorage.getItem("token");
            let formData = new FormData();
            formData.append('user_id', localStorage.getItem('loginid'));
            formData.append('categoryIds', categoryIds);
            formData.append('usertype', 'customer');
            const headers = {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            };
            this.loader = true;
            this.axios.post(
                    process.env.VUE_APP_API_ENDPOINT + '/search-favsupp-list',formData, {
                        headers
                    })
                .then(response => {
                    console.log('response-', response.data.data);
                    if (response.status == 200) {
                        this.favSupplierList = response.data.data;
                        const categorized = {};
                        this.favSupplierList.forEach((item) => {
                            if (!categorized[item.category_id]) {
                            const categoryName = this.catoptions.find(option => option.value === item.category_id)?.title || ''; // Find the category name from catoptions
                            categorized[item.category_id] = {
                                category_name: categoryName,
                                suppliers: [],
                                viewmore:true
                            };
                            }
                            categorized[item.category_id].suppliers.push(item);
                        });
                        this.categorizedFavSuppliers = Object.values(categorized);
                        this.loader = false;
                    }
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status == 401 || error.response.status == 404 || error.response.status == 400) {
                            localStorage.clear();
                            if (this.$route.name != 'home') {
                                this.$router.push({
                                    name: 'home'
                                });
                            }
                        }
                    } else {
                        if (error.message == "Network Error") {
                            localStorage.clear();
                            if (this.$route.name != 'home') {
                                this.$router.push({
                                    name: 'home'
                                });
                            }
                        }
                    }
                });
          
            },
        requestAQuote(supplierid, categoryid) {
            this.$router.push({
                'path':'/buyer/create-new-quote',
                query:{'supplier_id':supplierid, category_id:categoryid, 'flag':'request_to_favsupp'}
            }) 
        }
        },
    watch: {},
    mounted() {
        this.usertype = localStorage.getItem('userType');
        // this.getFavSupplierList();
        this.allcategory();
        this.updateCategorizedFavSuppliers();
    }
};
</script>

    
<style scoped>
/* .childhead{
        color:#000;
        font-weight:800;
    } */
.submitQue {
    margin-right: 95px;
}

a.skipForNow {
    right: 0 !important;
    position: absolute;
    top: 10px;
    /* margin-left: 15px; */
    margin-right: 20px;
    text-decoration: underline;
    color: #8c8ca3;
}

a.skipForNow:hover {
    color: rgb(220, 183, 37);
}

.unitdropdownIcon {
    right: 22px !important;
}

.submitbtn {
    padding: 10px !important;
    background-color: #09b7d9 !important;
}
.devideDiv{
    cursor: pointer;
}
.devideDiv svg.starIcon{
    color: rgb(255,197, 3) !important;
}
.devideDiv svg{
    color: rgb(197, 2, 2) !important;
    font-size: 15px;
    cursor: pointer;
}
/* .otherCardDiv{
     margin-left: -70px;
} */
.categoriDiv h4{
    font-weight: 800 !important;
    color: #045be3 !important;
    font-size: 15px !important;
}
svg.unFav{
    color: #434343  !important;
}
.multiselsearchBox{
    height: 40px;
}
.p-multiselect-filter-container svg{
    top: 10px !important;
}
.p-multiselect-item .p-checkbox .p-checkbox-box{
    width: 20px !important;
    height: 20px !important;
}
.p-multiselect-panel .p-multiselect-header>div.p-checkbox{
  display: block !important;
}
.favSupplierCard{
    cursor: pointer;
}
.disableFavSupplierCard{
  pointer-events: none;
}
</style>
