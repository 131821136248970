<template>
<ViewUserPage />
</template>

    
<script>
import ViewUserPage from '@/modules/users/ViewUserPage.vue'
export default {
    name: 'ViewUserView',
    components: {
        ViewUserPage
    }
}
</script>
