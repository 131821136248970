<template>
<RaiseQuoteView />
</template>

<script>
import RaiseQuoteView from '@/modules/raisequote'
export default {
    name: 'BuyerQuoteViewPageView',
    components: {
        RaiseQuoteView
    }
}
</script>
