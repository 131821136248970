<template lang="html">
<AddCategoryPage />
</template>

<script>
import AddCategoryPage from '@/components/lookupvalues/AddCategoryPage.vue';
export default {
    components: {
        AddCategoryPage
    },
    data: () => {
        return {};
    }
};
</script>

<style lang="scss" scoped></style>
