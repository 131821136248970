<template>
<AdminPage />
</template>

<script>
import AdminPage from '@/modules/adminpage'
export default {
    name: 'AdminView',
    components: {
        AdminPage
    },
    mounted() {}
}
</script>
