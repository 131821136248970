<template>
<ApprovedOrdersPage />
</template>

<script>
import ApprovedOrdersPage from '@/modules/orderlist/orders/ApprovedOrdersPage.vue'
export default {
    name: 'ApprovedOrdersView',
    components: {
        ApprovedOrdersPage
    }
}
</script>
