<template lang="html">
<section class="section section-odd section-border mb-0">
    <div class="container">
        <h1 class="text-center">Privacy Policy</h1>
    </div>
</section>
<section class="section-row">
    <div class="section-content">
        <p class="">SupplierDirect ("we," "us," or "our") is committed to protecting the privacy and confidentiality of our users' personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your personal information when you use our website or services.</p>        
        <!-- 
        <p>Name: XYZ<br>Email: <router-link to="#">xyz@xyz.com</router-link> 
        </p> 
        --> 
        <!-- RB 20240718 Commented this line out as dont see why we should put name and email on at this point? -->
    </div>
</section>
<section class="section section-odd">
    <div class="section-content">
        <p class="section-desc">
            Information We Collect</p>
        <p>Personal Information: We may collect personal information, such as your name, email address, contact details, and any other information you provide to us voluntarily.
        </p>
        <ul class="list-dots">
            <li>Usage Information: We may collect information about your interactions with our website, including your IP address, browser type, device information, and browsing activities.
            </li>
        </ul>
    </div>
</section>
<section class="section-row">
    <div class="section-content">
        <p class="section-desc">
            How We Use Your Information</p>
        <p>In general. We may use information that we collect about you to:</p>
        <ul class="list-dots">
            <li>
                Provide Services: We use your personal information to provide and improve our services, including facilitating transactions, customer support, and website functionality.
            </li>
            <li>Communication: We may use your contact information to communicate with you regarding updates, promotions, or important notices.
            </li>
            <li>
                Analytics and Improvements: We analyse usage data to enhance and optimize our website and services.
            </li>
            <li>
                Legal Obligations: We may use and disclose your information as required by applicable laws, regulations, or legal processes.
            </li>
        </ul>
    </div>
</section>
<section class="section section-odd">
    <div class="section-content">
        <p class="section-desc">
            Information Sharing</p>
        <ul class="list-dots">
            <li>
                Third-Party Service Providers: We may share your information with trusted third-party service providers who assist us in operating our business and providing services.
            </li>
            <li>Legal Compliance: We may disclose your information to comply with legal obligations, enforce our policies, or protect our rights, property, or safety.
            </li>
        </ul>
    </div>
</section>
<section class="section-row">
    <div class="section-content">
        <p class="section-desc">
            Data Security</p>
        <p>We take appropriate measures to protect your personal information from unauthorized access, use, or disclosure. However, please note that no data transmission or storage can be guaranteed as 100% secure.</p>
    </div>
</section>
<section class="section section-odd">
    <div class="section-content">
        <p class="section-desc">
            Your Choices</p>
        <p>You can choose to opt-out of receiving promotional communications from us and update your personal information by contacting us at [contact information].</p>
    </div>
</section>
<section class="section-row">
    <div class="section-content">
        <p class="section-desc">
            Changes to the Privacy Policy</p>
        <p>
            We may update this Privacy Policy periodically to reflect changes in our practices or legal requirements. We encourage you to review this policy periodically to stay informed about how we handle your personal information.
        </p>
    </div>
</section>
<section class="section section-odd">
    <div class="section-content">
        <p class="section-desc">
            How to contact us</p>
        <p>If you have any questions, concerns, or requests regarding this Privacy Policy or our data practices, please contact us at [contact information].</p>
        <p>By using our website or services, you signify your acceptance of this Privacy Policy.</p>
    </div>
</section>
<section class="section section-no-border">
</section>
</template>

<script>
export default {
    name: 'PrivacypolicyPage',
    data: () => ({}),
};
</script>

<style scoped>
.section {
    padding: 20px 0 !important;
    border-bottom: 1px solid #e3e6ea;
}
.layout-content {
    margin-top: 110px !important;
}
.section-row {
    padding: 25px 0 !important;
}
.section-desc{
 margin-bottom: 10px !important;
}
</style>
