<template>
<EditUserPage />
</template>

<script>
import EditUserPage from '@/modules/users/EditUserPage.vue'
export default {
    name: 'EditUserView',
    components: {
        EditUserPage
    }
}
</script>
