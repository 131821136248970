<template>
<RaiseQuotePage />
</template>

<script>
import RaiseQuotePage from "./_components/BuyerQuoteDetails.vue"
export default {
    name: "RaiseQuotePageModule",
    components: {
        RaiseQuotePage
    }
}
</script>
