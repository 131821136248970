<template>
<PrivacyView />
</template>

<script>
import PrivacyView from '@/modules/privacy'
export default {
    name: 'PrivacyPageView',
    components: {
        PrivacyView
    }
}
</script>
