<template>
<HomePage />
</template>

<script>
import HomePage from '@/modules/home'
export default {
    name: 'HomeView',
    components: {
        HomePage
    }
}
</script>
