<template>
<ContactUsView />
</template>

<script>
import ContactUsView from "./_components/ContactUsView.vue"
export default {
    name: "ContactUsModule",
    components: {
        ContactUsView
    }
}
</script>
