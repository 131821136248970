<template>
<ForgetPswdView />
</template>

<script>
import ForgetPswdView from "./_components/ForgetPassword.vue"
export default {
    name: "ForgetPswdModule",
    components: {
        ForgetPswdView
    }
}
</script>
