<template>
<supplierSpecificPage />
</template>

    
<script>
import supplierSpecificPage from '@/components/SupplierSpecificQuote.vue'
export default {
    name: 'supplierSpecific',
    components: {
        supplierSpecificPage
    }
}
</script>
