<template>
<PurchaseCreditPage />
</template>

<script>
import PurchaseCreditPage from '@/modules/creditpoint'
export default {
    name: 'PurchaseCreditPageView',
    components: {
        PurchaseCreditPage
    }
}
</script>
