<template>
<v-row justify="center">
    <v-dialog persistent v-model="show" width="860px" class="modalShowContainer">
        <v-card>
            <v-card-title>
                <span class="text-h5 cancelquotehead">
                    <h4>Cancel Quote Questionnaire </h4>
                </span>
                <font-awesome-icon :icon="['fasl', 'xmark']" class="modalCloseIcon" @click="close()" />
            </v-card-title>
            <v-card-text>
                <v-container>
                    <section class="widget widget-white feedbackmodalwidget">
                        <div class="widget-body questionsDiv">
                            <div class="form">
                                <!-- <div class="row">
                                    <div class="col-12 col-md-12 col-sm-12 col-lg-12 mb-4">
                                        <h3>1.Reason for Not Purchasing:</h3>
                                        <h3>"{{ this.reason_for_not_purchase_q1 }}"</h3>
                                        <div class="otheroptionDiv">
                                            <textarea name="otheroption" v-model="this.reason_for_not_purchase" rows="3" cols="20" id="otheroption" class="input-control inputField  mt-2"></textarea>
                                            <div class="validator error" v-for="error of v$.reason_for_not_purchase.$errors" :key="error.$uid">
                                                {{ error.$message }}
                                            </div>
                                        </div>
                                        <h3>2.Price Sensitivity:</h3>
                                        <h4>"{{this.price_sensitive_q1}}"</h4>
                                        <div class="answerdiv mt-5 mb-5">
                                            <div class="ans1 form-check d-flex flex-row align-center mb-2" @click="handleRadioClick('Yes','q1_ans')">
                                                <input class="form-check-input" type="radio"  :value="1"  v-model="price_sensitive_q1_ans['Yes']" name="q2ans" id="q2ans1">
                                                <label class="form-check-label anslabel" for="q2ans1">
                                                    Yes
                                                </label>
                                            </div>
                                            <div class="ans2 form-check d-flex flex-row align-center mb-2" @click="handleRadioClick('No','q1_ans')">
                                                <input class="form-check-input" type="radio"  :value="1" v-model="price_sensitive_q1_ans['No']"  name="q2ans" id="q2ans2">
                                                <label class="form-check-label anslabel" for="q2ans2">
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                        <h4>"{{this.price_sensitive_q2}}"</h4>
                                        <div class="answerdiv mt-5 mb-5">
                                            <div class="ans1 form-check d-flex flex-row align-center mb-2" @click="handleRadioClick('Yes','q2_ans')">
                                                <input class="form-check-input" type="radio" :value="1"  v-model="price_sensitive_q2_ans['Yes']" name="q22ans" id="q2ans21">
                                                <label class="form-check-label anslabel" for="q2ans21">
                                                    Yes
                                                </label>
                                            </div>
                                            <div class="ans2 form-check d-flex flex-row align-center mb-2" @click="handleRadioClick('No','q2_ans')">
                                                <input class="form-check-input" type="radio" :value="1"  v-model="price_sensitive_q2_ans['No']" name="q22ans" id="q2ans22">
                                                <label class="form-check-label anslabel" for="q2ans22">
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                        <h3>3.Product Fit:</h3>
                                        <h4>"{{this.product_fit_q1}}"</h4>
                                        <div class="answerdiv mt-5 mb-5">
                                            <div class="ans1 form-check d-flex flex-row align-center mb-2" @click="handleRadioClick('Yes','productfit_ans')">
                                                <input class="form-check-input" type="radio" name="q23ans" :value="1"  v-model="product_fit_q1_ans['Yes']"  id="q2ans31">
                                                <label class="form-check-label anslabel" for="q2ans31">
                                                    Yes
                                                </label>
                                            </div>
                                            <div class="ans2 form-check d-flex flex-row align-center mb-2" @click="handleRadioClick('No','productfit_ans')">
                                                <input class="form-check-input" type="radio" name="q23ans" id="q2ans32" :value="1"  v-model="product_fit_q1_ans['No']">
                                                <label class="form-check-label anslabel" for="q2ans32">
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                        <h3>4.Shipping Concerns:</h3>
                                        <h4>"{{this.shopping_concern}}"</h4>
                                        <div class="answerdiv mt-5 mb-5">
                                            <div class="ans1 form-check d-flex flex-row align-center mb-2" @click="handleRadioClick('Yes','shopping_ans')">
                                                <input class="form-check-input" type="radio" name="q24ans" id="q2ans41" :value="1"  v-model="shopping_concern_ans['Yes']">
                                                <label class="form-check-label anslabel" for="q2ans41">
                                                    Yes
                                                </label>
                                            </div>
                                            <div class="ans2 form-check d-flex flex-row align-center mb-2" @click="handleRadioClick('No','shopping_ans')">
                                                <input class="form-check-input" type="radio" name="q24ans" id="q2ans42" :value="1"  v-model="shopping_concern_ans['No']">
                                                <label class="form-check-label anslabel" for="q2ans42">
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                                <h3>Reason for Not Purchasing:</h3>
                                <div class="row" v-for="item in this.allquestions" :key="item.id">
                                    <div class="col-12 col-md-12 col-sm-12 col-lg-12 mb-4" :id="item.id">
                                        <h3>{{item.parent_question}}</h3>
                                        <div class="answerdiv mt-5 mb-5">
                                            <div class="ans1 form-check d-flex flex-row align-center mb-2" v-for="i in item.ans_length" :key="i">
                                                <input class="form-check-input childquestion" type="radio" 
                                                v-model="this.selectedAnswers['qtype-'+item.question_type_id+'_'+'q-'+item.question_id+'_'+'a-'+item['ans_id'+i]]" 
                                                :name="'parentqans'+item.id" 
                                                :id="'q'+item.id+'ans'+i" :value="item['ans_id'+i]"
                                                @change="handleRadioChange(item.question_type_id, item.question_id, item['ans_id' + i], $event)"
                                                >
                                                <label class="form-check-label anslabel" :for="'q'+item.id+'ans'+i">
                                                    {{ item['answer'+i] }}
                                                </label>
                                                <input  type="text" name="OtherCatBox" cols="3" rows="2" v-model="this.reason" v-if="item['ans_id'+i] == 16"  
                                                id="OtherCatBox" class="input-control circularfield w-50"/>
                                                <input  type="text" name="OtherCatBox" cols="3" rows="2" v-model="this.shoppingOptionIssue" v-if="item['ans_id'+i] == 19"  
                                                id="OtherCatBox" class="input-control circularfield w-50"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="align-center col-12 col-sm-12 col-md-12 col-lg-12 mb-0">
                                <router-link id="feedback" name="feedback" class="btn btn-primary
                                    block circularfield align-center" to="#" v-b @click="submitCancelResp">Submit</router-link>
                            </div>
                        </div>
                    </section>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</v-row>
</template>

<script>
import {
    ref
} from "vue";
import useVuelidate from '@vuelidate/core';
import {
    helpers,
    required,
    minLength,
    maxLength,
    integer,
    email,
    sameAs
} from '@vuelidate/validators';
export default {
    name: 'cancelQuote',
    components: {},
    props: {
        visible: {
            type: String
        },
        quotationid:{
            type: Number
        },
        questionstage:{
            type: String
        }
    },
    setup() {
        return {
            v$: useVuelidate()
        }
    },
    data: () => ({
        allquestions:[],
        finalSelData:[],
        othercatName:'',
        selectedAnswers: {},
        reason:'',
        shoppingOptionIssue:'',
        reason_for_not_purchase_q1:"We noticed you didn't complete your purchase. Could you let us know why?",
        price_sensitive_q1:'Was the price higher than you expected?',
        price_sensitive_q2:'Was the price lower than you expected?',
        product_fit_q1:'Did you find the product met your needs?',
        shopping_concern:'Were there any issues with the shipping options?',
        price_sensitive_q1_ans:{'Yes':false, 'No':false},
        price_sensitive_q2_ans:{'Yes':false, 'No':false},
        product_fit_q1_ans:{'Yes':false, 'No':false},
        shopping_concern_ans:{'Yes':false, 'No':false},
        reason_for_not_purchase:'',
    }),
    validations() {
        return {
            reason_for_not_purchase: {
                required: helpers.withMessage('This field is required', required)
            }
        }
    },
    computed: {
        show: {
            get() {
                return this.visible;
            },
            set(value) {
                if (!value) {
                    this.$emit('close');
                }
            },
        },
    },
    methods: {
        handleRadioClick(option, flag){
            if(flag==='q1_ans'){
                this.price_sensitive_q1_ans = {
                    'Yes': option === 'Yes'?true:false,
                    'No': option === 'No'?true:false
                };
            }
            else if(flag==='productfit_ans'){
                this.product_fit_q1_ans = {
                    'Yes': option === 'Yes'?true:false,
                    'No': option === 'No'?true:false
                };
            }
            else if(flag==='shopping_ans'){
                this.shopping_concern_ans = {
                    'Yes': option === 'Yes'?true:false,
                    'No': option === 'No'?true:false
                };
            }
            else{
                this.price_sensitive_q2_ans = {
                    'Yes': option === 'Yes'?true:false,
                    'No': option === 'No'?true:false
                };
            }
        },
        close() {
            this.$emit('close');
        },
        submitque() {
            const result = this.v$.reason_for_not_purchase.$validate();
            if (result == false) {
                return
            }
            console.log('price_sensitive_q1_ans-', this.price_sensitive_q1_ans, this.quotationid);
            console.log('productfit_ans-', this.product_fit_q1_ans)
            console.log('shopping_ans-', this.shopping_concern_ans)
            console.log('price_sensitive_q2_ans-', this.price_sensitive_q2_ans)
            let price_sensitive_q1_answer='', price_sensitive_q2_answer='', product_fit_q1_answer='',shopping_concern_answer='' ;
            for(const[key, value] of Object.entries(this.price_sensitive_q1_ans)){
                if(value==1){
                    price_sensitive_q1_answer = key;
                }
            }
            for(const[key, value] of Object.entries(this.product_fit_q1_ans)){
                if(value==1){
                    product_fit_q1_answer = key;
                }
            }
            for(const[key, value] of Object.entries(this.shopping_concern_ans)){
                if(value==1){
                    shopping_concern_answer = key;
                }
            }
            for(const[key, value] of Object.entries(this.price_sensitive_q2_ans)){
                if(value==1){
                    price_sensitive_q2_answer = key;
                }
            }
            var ques_ans_array = [];
            if (this.reason_for_not_purchase != '') {
                ques_ans_array.push({
                    "q": this.reason_for_not_purchase_q1,
                    "a": this.reason_for_not_purchase
                })
            }
            if (price_sensitive_q1_answer != '') {
                ques_ans_array.push({
                    "q": this.price_sensitive_q1,
                    "a": price_sensitive_q1_answer
                })
            }
            if (product_fit_q1_answer != '') {
                ques_ans_array.push({
                    "q": this.product_fit_q1,
                    "a": product_fit_q1_answer
                })
            }
            if (shopping_concern_answer != '') {
                ques_ans_array.push({
                    "q": this.shopping_concern,
                    "a": shopping_concern_answer
                })
            }
            if (price_sensitive_q2_answer != '') {
                ques_ans_array.push({
                    "q": this.price_sensitive_q2,
                    "a": price_sensitive_q2_answer
                })
            }
            var loginId = localStorage.getItem('loginid');
            var token = localStorage.getItem('token');
            let formData = new FormData();
            if (ques_ans_array.length > 0) {
                for (let i = 0; i < ques_ans_array.length; i++) {
                    var d = JSON.stringify(ques_ans_array[i]);
                    console.log('ques d-', d);
                    formData.append('ques_ans_array[]', d);
                }
            }
            formData.append('customer_id', loginId);
            formData.append('parent_ques_id', 3);
            formData.append('questionnaireType', 'cancel_quotation');
            this.loader = true;
            var headers = {
                'Authorization': 'Bearer' + token,
                'Content-Type': 'multipart/form-data'
            }
            this.axios.post(process.env.VUE_APP_API_ENDPOINT + `/questionnaire`, formData, {
                    headers
                })
                .then(async(response) => {
                    let formData = new FormData();
                    formData.append('flag', 'cancel');
                    formData.append('_method', 'put');
                    await this.axios.post(
                            process.env.VUE_APP_API_ENDPOINT + `/update-response/${this.quotationid}`, formData, {
                                headers
                            })
                        .then((response) => {
                            this.loader = false;
                            this.$swal.fire({
                                icon: 'success',
                                title: 'Questions submitted and successfully cancelled quotation.',
                                showConfirmButton: true,
                                timer: 5000
                            }).then(() => {
                                this.close();
                                if(this.questionstage !=''){
                                    this.$router.push({
                                        path: '/buyer/order'
                                    });
                                }
                                else{
                                    this.$emit('refreshdata');
                                }
                            })
                        })
                        .catch((error) => {
                        })
                })
                .catch((error) => {

            });
        },
        getAllQuestion(){
            let token = localStorage.getItem('token');
            const headers = {
                'Authorization': 'Bearer' + token,
                'Content-Type': 'multipart/form-data'
            }
            this.axios.get(process.env.VUE_APP_API_ENDPOINT + `/questionnaire`, {
                    headers
                })
                .then((response) => {
                    var data = response.data.data;
                    this.allquestions = data.filter(item=>item.questiontype === 'customer_cancellation');
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status == 401 || error.response.status == 404 || error.response.status == 400) {
                            localStorage.clear();
                            if (this.$route.name != 'home') {
                                this.$router.push({
                                    name: 'home'
                                });
                            }
                        }
                    } else {
                        if (error.message == "Network Error") {
                            localStorage.clear();
                            if (this.$route.name != 'home') {
                                this.$router.push({
                                    name: 'home'
                                });
                            }
                        }
                    }
                })
        },
        handleRadioChange(qtypeid, qid, ansid, e){
            const key = `qtype-${qtypeid}_q-${qid}_a-${ansid}`;
            this.finalSelData.push(key);
        },
        submitCancelResp(){
            console.log('quotationid-', this.quotationid)
            if(this.finalSelData.length ==0){
                this.$swal.fire({
                    icon: 'error',
                    title: 'Please respond with one option',
                    showConfirmButton: true,
                    timer: 5000
                });
                return;
            }
            let token = localStorage.getItem('token');
            let loginid = localStorage.getItem('loginid')
            const headers = {
                'Authorization': 'Bearer' + token,
                'Content-Type': 'multipart/form-data'
            }
            var q1=[], q2=[], q3=[], q4=[], q5=[];
            for(let [k, v] of Object.entries(this.finalSelData)){
            if(v.includes('qtype-2_q-5')){
                q1.push(v);
            }
            else if(v.includes('qtype-2_q-6')){
                q2.push(v);
            }
            else if(v.includes('qtype-2_q-7')){
                q3.push(v);
            }
            else if(v.includes('qtype-2_q-8')){
                q4.push(v);
            }
            else if(v.includes('qtype-2_q-9')){
                q5.push(v);
            }
            }
            console.log
            ('q1', q1, q2,q3,q4,q5);
            var t1 = q1.length>0?q1[q1.length-1]:[];
            var t2= q2.length>0?q2[q2.length-1]:[];
            var t3=q3.length>0?q3[q3.length-1]:[];
            var t4=q4.length>0?q4[q4.length-1]:[];
            var t5=q5.length>0?q5[q5.length-1]:[];

            var ques1 =  t1.length>0?t1.split('_'):[];
            var ques2 = t2.length>0?t2.split('_'):[];
            var ques3 = t3.length>0?t3.split('_'):[];
            var ques4 = t4.length>0?t4.split('_'):[];
            var ques5 = t5.length>0?t5.split('_'):[];

            var questionArray=[],answerArray=[];
            ques1.length>0?questionArray.push(ques1[1].split('-')[1]):[];
            ques2.length>0?questionArray.push(ques2[1].split('-')[1]):[];
            ques3.length>0?questionArray.push(ques3[1].split('-')[1]):[];
            ques4.length>0?questionArray.push(ques4[1].split('-')[1]):[];
            ques5.length>0?questionArray.push(ques5[1].split('-')[1]):[];
            
            ques1.length>0?answerArray.push(ques1[2].split('-')[1]):[];
            ques2.length>0?answerArray.push(ques2[2].split('-')[1]):[];
            ques3.length>0?answerArray.push(ques3[2].split('-')[1]):[];
            ques4.length>0?answerArray.push(ques4[2].split('-')[1]):[];
            ques5.length>0?answerArray.push(ques5[2].split('-')[1]):[];
            
            console.log('qquestionArray', questionArray, answerArray);
            let formData = new FormData();
            formData.append('questionIds', questionArray);
            formData.append('answerIds', (answerArray));
            formData.append('customerid', loginid);
            formData.append('qtype_id', 2);
            formData.append('quotationId',  this.quotationid);
            formData.append('reason',  ques1.length>0&&ques1[2].split('-')[1]==16?this.reason:null);
            formData.append('shoppingOptionIssue',  ques5.length>0&&ques5[2].split('-')[1]==19?this.shoppingOptionIssue:null);
            this.axios.post(process.env.VUE_APP_API_ENDPOINT + `/questionnaire`, formData, {
                    headers
                })
                .then((response) => {
                    this.loader = false;
                    this.$swal.fire({
                        icon: 'success',
                        title: 'Successfully submit your response.',
                        showConfirmButton: true,
                        timer: 5000
                    }).then(() => {
                        this.close();
                        this.$emit('refreshdata');
                    })
                })
                .catch((error) => {
                });
        }
    },
    mounted() {
        this.getAllQuestion();
    }
}
</script>
