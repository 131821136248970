<template>
<ForgetPasswordPage />
</template>

<script>
import ForgetPasswordPage from '@/modules/forgetpswd'
export default {
    name: 'ForgetPswdPageView',
    components: {
        ForgetPasswordPage
    }
}
</script>
