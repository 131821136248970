<template>
<CreateQuoteView />
</template>

<script>
import CreateQuoteView from '@/modules/raisequote/RequestQuote.vue'
export default {
    name: 'CreateQuoteViewPage',
    components: {
        CreateQuoteView
    }
}
</script>
