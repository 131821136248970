<template>
<CreditView />
</template>

<script>
import CreditView from "./_components/CreditView.vue"
export default {
    name: "creditpointModule",
    components: {
        CreditView
    }
}
</script>
