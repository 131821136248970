<template lang="html">
<AboutUs />
</template>

<script>
import AboutUs from '@/components/AboutUsPage.vue';
export default {
    name: 'AboutUsModule',
    components: {
        AboutUs
    },
    transition: 'zoom',
};
</script>

<style lang="scss" scoped></style>
