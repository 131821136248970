<template lang="html">
<div class="werehouseContainer">
    <div class="form">
        <section class="widget widget-white werehouseWidget">
            <div class="widget-body">
                <div class="form-rows p-t-no">
                    <div class="row pt-3 pl-3">
                        <div class="col-12 col-md-4 col-sm-4 col-lg-4">
                            <ChipsMultiSelect v-model="searchItem" display="chip" :options="this.catoptions" 
                            filter optionLabel="title" :maxSelectedLabels="this.catoptions.length" optionValue="value" class="select select-sm multiselsearchBox w-full md:w-20rem" style="margin: 1px !important;" />
                            <div class="select__arrow dropdownIcom unitdropdownIcon"></div>
                        </div>
                        <div class="col-12  col-md-4 col-sm-4 col-lg-4">
                            <button class="ps-btn success submitbtn" @click.prevent="searchCategory">Search</button>
                        </div>
                        <div class="col-12  col-md-4 col-sm-4 col-lg-4"></div>
                    </div>
                </div>
                <div class="form-rows p-t-no"  v-for="(category, index) in categorizedFavSuppliers" :key="index">
                    <div class="col-md-12 mt-8 quesdiv">
                        <div class="form categoriDiv">
                            <h4>{{ category.category_name }}</h4>
                            <div class="row">
                                <div class="col-12 col-md-2 col-sm-2 col-lg-2 mb-0 cardDiv" :class="item.id>1?'otherCardDiv':''" v-for="(item, itemIndex) in category.suppliers.slice(0, category.viewmore ? 2 : category.suppliers.length)" :key="item.id">
                                   <Card style="overflow: hidden" :id="itemIndex">
                                        <template #header>
                                            <div class="ppimgDiv">
                                                <img alt="user_profile_pic" class="img-fluid ppicture" v-if="item.user_profile.profile_picture ==null" :src="this.suppimg" style="margin-left: 110%;"  />
                                         <img alt="user_profile_pic" class="img-fluid ppicture" v-else :src="`${this.srcimage+'uploads/profiles_pictures/'
                                        +item.user_profile.profile_picture}`" style="margin-left: 110%;"  />
                                            </div>
                                        </template>
                                        <template #content>
                                            <div class="flex-wrap devideDiv align-content-center mt-5">
                                                <sapn>{{item.userrole.ref_id}}</sapn><br/>
                                                <sapn style="color:crimson" v-if="item.userrole.approval_status=='Disapproved'">{{item.userrole.approval_status}}</sapn>
                                            </div>
                                        </template>
                                        <template #footer>
                                            <div class="flex gap-3 justify-content-between p-2" :class="item.userrole.approval_status=='Disapproved'?'mt-1':'mt-5'">
                                                <div class="flex-wrap devideDiv">
                                                    <font-awesome-icon :icon="['fas', 'star']" class="starIcon" />
                                                    {{ item.rating }}
                                                </div>
                                                <div class="flex-wrap devideDiv">
                                                    <font-awesome-icon :icon="['fas', 'heart']" :id="item.id"/>
                                                    {{item.favorited_count}}
                                                </div>
                                            </div>
                                        </template>
                                    </Card>
                                </div>
                                <router-link to="#" style="top: 11rem;color: rgb(7 137 201);" @click="viewMore(index)" v-if="category.viewmore && category.suppliers.length > 2">View more...</router-link>
                                <router-link to="#" style="top: 11rem;color: rgb(7 137 201);" @click="viewLess(index)" v-else-if="!category.viewmore">View less</router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-rows p-t-no">
                    <div class="col-md-6 mb-4 mt-5">
                        <span class="icon-arrow-left backarrow mt-0 " @click="backtoparent">&nbsp;Back</span>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
</template>

<script>
export default {
    name: 'WerehousePageComponent',
    components: {},
    data() {
        return {
            siteval: null,
            loader: false,
            unit: [],
            viewmore: true,
            viewless: false,
            suppimg:require('@/assets/img/admin/default_user_img.png'),
            searchItem:[30,31,32],
            catoptions: [],
            favSupplierList:[],
            srcimage: process.env.VUE_APP_SET_PATH,
            categorizedFavSuppliers:[],
        };
    },
    methods: {
        backtoparent() {
            this.$router.back();
        },
        deleteFavSupp(id) {
            this.$swal({
                    title: 'Do you want to remove this supplier from your favourite list?',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    showCloseButton: true,
                    showLoaderOnConfirm: true,
                    customClass: 'warningswal'
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.favSupplierList = this.favSupplierList.map(item => {
                            if (item.id === id) {
                                return {
                                    ...item,
                                    active: false
                                }
                            } else {
                                return item;
                            }
                        })
                    }
                })
                .catch(() => {
                    console.error('An error occurred:', error);
                });
        },
        // viewMore() {
        //     let data = [{
        //             id: 4,
        //             refId: 'WSES-11104',
        //             catname: 'Sweets',
        //             favrate: 2,
        //             rating: 2.4,
        //             active: true,
        //             imgsrc: require('@/assets/img/vendor_img/vendor_img.png')
        //         },
        //         {
        //             id: 5,
        //             refId: 'WSES-11105',
        //             catname: 'Sweets',
        //             favrate: 2,
        //             rating: 2.4,
        //             active: true,
        //             imgsrc: require('@/assets/img/vendor_img/businesslady.jpg')
        //         }
        //     ]
        //     this.favSupplierList.push(...data);
        //     this.viewmore = false;
        //     this.viewless = true;
        // },
        // viewLess() {
        //     this.favSupplierList.splice(-2);
        //     this.viewmore = true;
        //     this.viewless = false;
        // },
        viewMore(index) {
            console.log('index -', index)
            this.categorizedFavSuppliers[index].viewmore = false;
        },
        viewLess(index) {
            this.categorizedFavSuppliers[index].viewmore = true;
        },
        searchCategory() {
            this.updateCategorizedFavSuppliers();
        },
        async allcategory() {
            var array = [];
            const headers = {
                'Authorization': 'Bearer ' + localStorage.getItem("token"),
            };
            await this.axios.get(process.env.VUE_APP_API_ENDPOINT + '/categorylist', {})
                .then(response => {
                    Object.values(response.data.data).forEach(value => {
                        if (this.$route.query.catid) {
                            if (this.$route.query.catid == value.id) {
                                this.form.categoryget = value.id;
                            }
                        }
                        array.push({
                            'value': value.id,
                            "title": value.name
                        });
                    });
                    this.catoptions = array;
                })
                .catch(err => {});
        },
        updateCategorizedFavSuppliers() {
            console.log('search-', this.searchItem);
            var categoryIds = [];
            for (const [key, value] of Object.entries(this.searchItem)) {

                categoryIds.push(value)
            }
            console.log('categoryIds-', categoryIds);
            var token = localStorage.getItem("token");
            let formData = new FormData();
            formData.append('user_id', localStorage.getItem('loginid'));
            formData.append('categoryIds', categoryIds);
            formData.append('usertype', 'admin');
            const headers = {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            };
            this.loader = true;
            this.axios.post(
                    process.env.VUE_APP_API_ENDPOINT + '/search-favsupp-list', formData, {
                        headers
                    })
                .then(response => {
                    console.log('response-', response.data.data);
                    if (response.status == 200) {
                        this.favSupplierList = response.data.data;
                        const categorized = {};
                        this.favSupplierList.forEach((item) => {
                            if (!categorized[item.category_id]) {
                                const categoryName = this.catoptions.find(option => option.value === item.category_id) ?.title || ''; // Find the category name from catoptions
                                categorized[item.category_id] = {
                                    category_name: categoryName,
                                    suppliers: [],
                                    viewmore: true 
                                };
                            }
                            // if(categorized[item.category_id].suppliers.length <=2){
                                categorized[item.category_id].suppliers.push(item);
                            // }
                        });
                        this.categorizedFavSuppliers = Object.values(categorized);
                        this.loader = false;
                    }
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status == 401 || error.response.status == 404 || error.response.status == 400) {
                            localStorage.clear();
                            if (this.$route.name != 'home') {
                                this.$router.push({
                                    name: 'home'
                                });
                            }
                        }
                    } else {
                        if (error.message == "Network Error") {
                            localStorage.clear();
                            if (this.$route.name != 'home') {
                                this.$router.push({
                                    name: 'home'
                                });
                            }
                        }
                    }
                });

        },
    },
    watch: {},
    mounted() {
        this.usertype = localStorage.getItem('userType');
        // this.getFavSupplierList();
        this.allcategory();
        this.updateCategorizedFavSuppliers();
    }
};
</script>

<style lang="scss" scoped>
.submitQue {
    margin-right: 95px;
}

a.skipForNow {
    right: 0 !important;
    position: absolute;
    top: 10px;
    /* margin-left: 15px; */
    margin-right: 20px;
    text-decoration: underline;
    color: #8c8ca3;
}

a.skipForNow:hover {
    color: rgb(220, 183, 37);
}

.unitdropdownIcon {
    right: 22px !important;
}

.submitbtn {
    padding: 10px !important;
    background-color: #09b7d9 !important;
}

// .devideDiv {
//     cursor: pointer;
// }

.devideDiv svg.starIcon {
    color: rgb(255, 197, 3) !important;
}

.devideDiv svg {
    color: rgb(197, 2, 2) !important;
    font-size: 15px;
    // cursor: pointer;
}

/* .otherCardDiv{
     margin-left: -70px;
} */
.categoriDiv h4 {
    font-weight: 800 !important;
    color: #045be3 !important;
    font-size: 15px !important;
}

svg.unFav {
    color: #434343 !important;
}

.multiselsearchBox {
    height: 40px;
}

.p-multiselect-filter-container svg {
    top: 10px !important;
}

.p-multiselect-item .p-checkbox .p-checkbox-box {
    width: 20px !important;
    height: 20px !important;
}

.p-multiselect-panel .p-multiselect-header>div.p-checkbox {
    display: block !important;
}

// .devideDiv svg ,.devideDiv{
//    pointer-events: none !important;
//    cursor: none !important;
// }

</style>
