<template>
<BuyerPage />
</template>

<script>
import BuyerPage from '@/modules/buyerpage'
export default {
    name: 'BuyerPageView',
    components: {
        BuyerPage
    }
}
</script>
