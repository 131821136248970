<template lang="html">
    <section class="section section-odd section-border mb-0">
        <div class="container">
            <h1 class="text-center">FAQs</h1>
        </div>
    </section>
    <section class="section-row">
        <div class="section-content">
            <p class="section-desc">
                What is SupplierDirect? </p>
            <p class="">
                SupplierDirect is an innovative online marketplace that connects customers with verified suppliers, offering a wide range of products at wholesale prices. Our platform simplifies the process of requesting quotes, receiving offers, and placing orders, all while ensuring a secure and seamless experience.
            </p>
        </div>
    </section>
    <section class="section section-odd">
        <div class="section-content">
            <p class="section-desc">
                How Does SupplierDirect Work?</p>
            <p>
                SupplierDirect streamlines the buying process by allowing customers to submit quote requests for their desired products. Verified suppliers then provide competitive offers based on the customer's requirements. Once an offer is accepted, customers can easily proceed to place their orders. (Add the internal link here to the long version of how it works for suppliers as well as customer)
            </p>
        </div>
    </section>
    <section class="section-row">
        <div class="section-content">
            <p class="section-desc">
                Who Can Use SupplierDirect?</p>
            <p>SupplierDirect is open to businesses, entrepreneurs, retailers, wholesalers, and individuals seeking high-quality products at competitive prices.</p>
        </div>
    </section>
    <section class="section section-odd">
        <div class="section-content">
            <p class="section-desc">
                Are the Suppliers Verified?</p>
            <p>
                Yes, we meticulously verify all our suppliers to ensure their credibility and product quality. This verification process helps maintain a trusted and reliable network of suppliers.
            </p>
        </div>
    </section>
    <section class="section-row">
        <div class="section-content">
            <p class="section-desc">
                How Do I Request Quotes?</p>
            <p>
                To request quotes, simply log in to your SupplierDirect account, navigate to the desired product category, and provide the required specifications. Verified suppliers will then submit offers tailored to your needs.
            </p>  
        </div>
    </section>
    <section class="section section-odd">
        <div class="section-content">
            <p class="section-desc">
                What Payment Options Are Available?</p>
            <p>
                We offer secure payment options, including credit/debit cards and online payment gateways, to facilitate easy and safe transactions.
            </p>    
            </div>
    </section>
    <section class="section-row">
        <div class="section-content">
            <p class="section-desc">
                How Can I Contact Customer Support?</p>
            <p>
                Our dedicated customer support team is available via email at support@supplierdirect.uk and live chat on our website. Feel free to reach out with any questions or concerns.
            </p>
        </div>
    </section>
    <section class="section section-odd">
        <div class="section-content">
            <p class="section-desc">
                How Do I Manage My Orders? </p>
            <p>
                Once you've placed an order, you can track its status, view your order history, and manage account settings from your SupplierDirect dashboard
            </p>
        </div>
    </section>
    <section class="section-row">
        <div class="section-content">
            <p class="section-desc">
                What Categories of Products Can I Find?</p>
            <p>
                SupplierDirect offers an extensive range of product categories, including office supplies, stationery, pet supplies, and perishables with long shelf lives, among others. (Link to customer request dashboard, or supplier request dashboard)
            </p>
        </div>
    </section>
    <section class="section section-odd">
        <div class="section-content">
            <p class="section-desc">
                Can I Request Samples?</p>
            <p>
                At SupplierDirect, we've streamlined our supplier verification process to ensure product quality. As a result, requesting samples is not necessary, as our verified suppliers are committed to delivering top-notch products. Although, some suppliers have gone the extra mile and offered samples if needed. Samples can be bought.
            </p>
        </div>
    </section>
    <section class="section-row">
        <div class="section-content">
            <p class="section-desc">
                What Are Segmented Favorites?</p>
            <p>
                Segmented Favorites is a feature that allows you to categorize and organize your preferred suppliers for easier access. You can create segments based on your business needs and product preferences.
            </p>
        </div>
    </section>
    <section class="section section-odd">
        <div class="section-content">
            <p class="section-desc">
                How Do I Become a Verified Supplier?</p>
            <p>
                Suppliers interested in joining SupplierDirect can apply to become verified by submitting necessary documentation and meeting our verification criteria. Reach out to supplier@supplierdirect.uk to learn more. (Link the supplier dashboard so they can submit all their information)
            </p>
        </div>
    </section>
    <section class="section-row">
        <div class="section-content">
            <p class="section-desc">
                How Do I Pay for Quotes?</p>
           <p>
            Customers can conveniently purchase quote credits or subscribe to a premium plan, giving you access to a certain number of quote requests per month. Each quote request uses a credit, and unused credits can be rolled over to the next month for subscribers.
           </p>
        </div>
    </section>
    <section class="section section-odd">
        <div class="section-content">
            <p class="section-desc">
                Is My Information Secure?</p>
            <p>
                Yes, we prioritize the security of your personal and financial information. We use advanced encryption and adhere to industry-standard security practices to ensure your data is safe.
            </p>
        </div>
    </section>
    <section class="section-row">
        <div class="section-content">
            <p class="section-desc">
                What Happens After I Place an Order? </p>
            <p>
                Once you've placed an order, you'll receive an order confirmation with details. Your selected supplier will process and fulfill the order, and you can track its delivery status through your account.
            </p>
        </div>
    </section>
    <section class="section section-odd">
        <div class="section-content">
            <p class="section-desc">
                Can I Cancel or Modify an Order?</p>
            <p>
                Order cancellations or modifications may be possible depending on the supplier's policies. Reach out to our customer support team as soon as possible, and we'll assist you in the process.
            </p>
        </div>
    </section>
    <section class="section-row">
        <div class="section-content">
            <p class="section-desc">
                How Do I Leave Supplier Reviews?</p>
            <p>
                After completing a successful transaction, you can leave a review for the supplier through your account. Your feedback helps other users make informed decisions.
            </p>
        </div>
    </section>
    <section class="section section-odd">
        <div class="section-content">
            <p class="section-desc">
                What Happens if There's an Issue with My Order? </p>
            <p>
                If you encounter any issues with your order, contact our customer support team immediately. We'll work with you and the supplier to resolve the matter promptly.
            </p> 
        </div>
    </section>
    <section class="section-row">
        <div class="section-content">
            <p class="section-desc">
                Do You Offer Discounts or Promotions?</p>
            <p>
                Yes, we periodically offer discounts, promotions, and loyalty programs to our users. Stay updated by subscribing to our newsletters and following us on social media.
            </p>
        </div>
    </section>
    <section class="section section-odd">
        <div class="section-content">
            <p class="section-desc">
                How Can I Stay Updated with SupplierDirect?</p>
            <p>
                To stay informed about the latest updates, promotions, and news, subscribe to our newsletters and follow us on social media. You can find links to our social media accounts on our website.
            </p>
        </div>
    </section>
    <section class="section section-odd section-border mb-0">
        <div class="container">
            <h2 class="text-center"> Sample Requests and more FAQ’s</h2>
        </div>
    </section>
    <section class="section-row">
        <div class="section-content">
            <p class="section-desc">
                Can I request samples of products before making a purchase?</p>
            <p>
                Our platform is designed to provide you with verified and quality-assured products, eliminating the need for sample requests. Each supplier undergoes a rigorous verification process, ensuring the products meet your expectations.
            </p>
        </div>
    </section>
    <section class="section section-odd">
        <div class="section-content">
            <p class="section-desc">
                How can I be confident about the quality of products without requesting samples? </p>
            <p>
                We prioritize quality assurance through our thorough supplier verification process. Rest assured that the products listed on our platform adhere to stringent quality standards.
            </p>
        </div>
    </section>
    <section class="section-row">
        <div class="section-content">
            <p class="section-desc">
                Can I still contact suppliers for more information about their products? </p>
            <p>
                Absolutely! You can communicate directly with suppliers to gather information about their products, specifications, and any other details you need to make informed decisions.
            </p>  
        </div>
    </section>
    <section class="section section-odd">
        <div class="section-content">
            <p class="section-desc">
                What if I have specific preferences or questions about a product?</p>
            <p>
                Our dedicated customer support team is here to assist you. Feel free to reach out with any questions or concerns you may have about the products or the purchasing process.
            </p>
        </div>
    </section>
    <section class="section-row">
        <div class="section-content">
            <p class="section-desc">
                Why have you opted for this approach? </p>
            <p>
                Our goal is to simplify your wholesale experience by providing verified and high-quality products. By eliminating the need for sample requests, we save you time and ensure a seamless process.
            </p>
        </div>
    </section>
    <section class="section section-odd">
        <div class="section-content">
            <p class="section-desc">
                What if I have unique requirements for my business? </p>
            <p>
                We understand that businesses have diverse needs. If you have specific requirements or questions, our support team is available to assist you and provide tailored solutions.
            </p>
        </div>
    </section>
    <section class="section-row">
        <div class="section-content">
            <p class="section-desc">
                How do I know the products will meet my expectations without seeing samples?</p>
            <p>
                Our stringent verification process involves detailed assessments to ensure the products align with your expectations. This process helps maintain the quality and authenticity of products listed on our platform.
            </p>
        </div>
    </section>
    <section class="section section-odd">
        <div class="section-content">
            <p class="section-desc">
                Can I trust that the products will be as described by the suppliers? </p>
            <p>
                Yes, you can! We verify the accuracy of product descriptions through our supplier verification process. This way, you can trust that the products you see are accurately represented.
            </p>
        </div>
    </section>
    <section class="section-row">
        <div class="section-content">
            <p class="section-desc">
                What if I still have doubts about a product's suitability for my needs? </p>
            <p>
                Feel free to communicate directly with the supplier to address any doubts or questions you have. They will be happy to provide additional information to help you make an informed decision.
            </p>
        </div>
    </section>
    <section class="section section-odd">
        <div class="section-content">
            <p class="section-desc">
                Is this approach unique to SupplierDirect? </p>
            <p>
                Yes, our approach is designed to simplify the wholesale experience by providing verified products and saving your time. We prioritize transparency and quality assurance for your peace of mind.
            </p>
        </div>
    </section>
    <section class="section section-no-border">
    </section>
    </template>
    
    <script>
    export default {
        name: 'PrivacypolicyPage',
        data: () => ({}),
    };
    </script>
    
    <style scoped>
    .section {
        padding: 20px 0 !important;
        border-bottom: 1px solid #e3e6ea;
    }
    .layout-content {
        margin-top: 110px !important;
    }
    .section-row {
        padding: 25px 0 !important;
    }
    .section-desc{
     margin-bottom: 10px !important;
    }
    </style>
    