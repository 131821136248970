<template>
<AddCategoryPage />
</template>

<script>
import AddCategoryPage from '@/modules/lookupvalues/CatSubCategory.vue'
export default {
    name: 'AddCategoryView',
    components: {
        AddCategoryPage
    }
}
</script>
