<template>
<AboutUsView />
</template>

<script>
import AboutUsView from "./_components/AboutUsView.vue"
export default {
    name: "AboutUsModule",
    components: {
        AboutUsView
    }
}
</script>
